@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200italic,400,600,700,900");
@import url("font-awesome.min.css");

/*
	Expanse by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

/* Reset */

	html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}

	article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
		display: block;
	}

	body {
		line-height: 1;
	}

	ol, ul {
		list-style: none;
	}

	blockquote, q {
		quotes: none;
	}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	body {
		-webkit-text-size-adjust: none;
	}

/* Box Model */

	*, *:before, *:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

/* Containers */

	.container {
		margin-left: auto;
		margin-right: auto;
	}

	.container.\31 25\25 {
		width: 100%;
		max-width: 1200px;
		min-width: 960px;
	}

	.container.\37 5\25 {
		width: 720px;
	}

	.container.\35 0\25 {
		width: 480px;
	}

	.container.\32 5\25 {
		width: 240px;
	}

	.container {
		width: 960px;
	}

	@media screen and (min-width: 737px) {

		.container.\31 25\25 {
			width: 100%;
			max-width: 1500px;
			min-width: 1200px;
		}

		.container.\37 5\25 {
			width: 900px;
		}

		.container.\35 0\25 {
			width: 600px;
		}

		.container.\32 5\25 {
			width: 300px;
		}

		.container {
			width: 1200px;
		}

	}

	@media screen and (min-width: 737px) and (max-width: 1200px) {

		.container.\31 25\25 {
			width: 100%;
			max-width: 1250px;
			min-width: 1000px;
		}

		.container.\37 5\25 {
			width: 750px;
		}

		.container.\35 0\25 {
			width: 500px;
		}

		.container.\32 5\25 {
			width: 250px;
		}

		.container {
			width: 1000px;
		}

	}

	@media screen and (max-width: 736px) {

		.container.\31 25\25 {
			width: 100%;
			max-width: 125%;
			min-width: 100%;
		}

		.container.\37 5\25 {
			width: 75%;
		}

		.container.\35 0\25 {
			width: 50%;
		}

		.container.\32 5\25 {
			width: 25%;
		}

		.container {
			width: 100% !important;
		}

	}

/* Grid */

	.row {
		border-bottom: solid 1px transparent;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.row > * {
		float: left;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.row:after, .row:before {
		content: '';
		display: block;
		clear: both;
		height: 0;
	}

	.row.uniform > * > :first-child {
		margin-top: 0;
	}

	.row.uniform > * > :last-child {
		margin-bottom: 0;
	}

	.row.\30 \25 > * {
		padding: 0 0 0 0px;
	}

	.row.\30 \25 {
		margin: 0 0 -1px 0px;
	}

	.row.uniform.\30 \25 > * {
		padding: 0px 0 0 0px;
	}

	.row.uniform.\30 \25 {
		margin: 0px 0 -1px 0px;
	}

	.row > * {
		padding: 0 0 0 40px;
	}

	.row {
		margin: 0 0 -1px -40px;
	}

	.row.uniform > * {
		padding: 40px 0 0 40px;
	}

	.row.uniform {
		margin: -40px 0 -1px -40px;
	}

	.row.\32 00\25 > * {
		padding: 0 0 0 80px;
	}

	.row.\32 00\25 {
		margin: 0 0 -1px -80px;
	}

	.row.uniform.\32 00\25 > * {
		padding: 80px 0 0 80px;
	}

	.row.uniform.\32 00\25 {
		margin: -80px 0 -1px -80px;
	}

	.row.\31 50\25 > * {
		padding: 0 0 0 60px;
	}

	.row.\31 50\25 {
		margin: 0 0 -1px -60px;
	}

	.row.uniform.\31 50\25 > * {
		padding: 60px 0 0 60px;
	}

	.row.uniform.\31 50\25 {
		margin: -60px 0 -1px -60px;
	}

	.row.\35 0\25 > * {
		padding: 0 0 0 20px;
	}

	.row.\35 0\25 {
		margin: 0 0 -1px -20px;
	}

	.row.uniform.\35 0\25 > * {
		padding: 20px 0 0 20px;
	}

	.row.uniform.\35 0\25 {
		margin: -20px 0 -1px -20px;
	}

	.row.\32 5\25 > * {
		padding: 0 0 0 10px;
	}

	.row.\32 5\25 {
		margin: 0 0 -1px -10px;
	}

	.row.uniform.\32 5\25 > * {
		padding: 10px 0 0 10px;
	}

	.row.uniform.\32 5\25 {
		margin: -10px 0 -1px -10px;
	}

	.\31 2u, .\31 2u\24 {
		width: 100%;
		clear: none;
		margin-left: 0;
	}

	.\31 1u, .\31 1u\24 {
		width: 91.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\31 0u, .\31 0u\24 {
		width: 83.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\39 u, .\39 u\24 {
		width: 75%;
		clear: none;
		margin-left: 0;
	}

	.\38 u, .\38 u\24 {
		width: 66.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\37 u, .\37 u\24 {
		width: 58.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\36 u, .\36 u\24 {
		width: 50%;
		clear: none;
		margin-left: 0;
	}

	.\35 u, .\35 u\24 {
		width: 41.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\34 u, .\34 u\24 {
		width: 33.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\33 u, .\33 u\24 {
		width: 25%;
		clear: none;
		margin-left: 0;
	}

	.\32 u, .\32 u\24 {
		width: 16.6666666667%;
		clear: none;
		margin-left: 0;
	}

	.\31 u, .\31 u\24 {
		width: 8.3333333333%;
		clear: none;
		margin-left: 0;
	}

	.\31 2u\24 + *,
	.\31 1u\24 + *,
	.\31 0u\24 + *,
	.\39 u\24 + *,
	.\38 u\24 + *,
	.\37 u\24 + *,
	.\36 u\24 + *,
	.\35 u\24 + *,
	.\34 u\24 + *,
	.\33 u\24 + *,
	.\32 u\24 + *,
	.\31 u\24 + * {
		clear: left;
	}

	.\-11u {
		margin-left: 91.66667%;
	}

	.\-10u {
		margin-left: 83.33333%;
	}

	.\-9u {
		margin-left: 75%;
	}

	.\-8u {
		margin-left: 66.66667%;
	}

	.\-7u {
		margin-left: 58.33333%;
	}

	.\-6u {
		margin-left: 50%;
	}

	.\-5u {
		margin-left: 41.66667%;
	}

	.\-4u {
		margin-left: 33.33333%;
	}

	.\-3u {
		margin-left: 25%;
	}

	.\-2u {
		margin-left: 16.66667%;
	}

	.\-1u {
		margin-left: 8.33333%;
	}

	@media screen and (min-width: 737px) {

		.row > * {
			padding: 25px 0 0 25px;
		}

		.row {
			margin: -25px 0 -1px -25px;
		}

		.row.uniform > * {
			padding: 25px 0 0 25px;
		}

		.row.uniform {
			margin: -25px 0 -1px -25px;
		}

		.row.\32 00\25 > * {
			padding: 50px 0 0 50px;
		}

		.row.\32 00\25 {
			margin: -50px 0 -1px -50px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 50px 0 0 50px;
		}

		.row.uniform.\32 00\25 {
			margin: -50px 0 -1px -50px;
		}

		.row.\31 50\25 > * {
			padding: 37.5px 0 0 37.5px;
		}

		.row.\31 50\25 {
			margin: -37.5px 0 -1px -37.5px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 37.5px 0 0 37.5px;
		}

		.row.uniform.\31 50\25 {
			margin: -37.5px 0 -1px -37.5px;
		}

		.row.\35 0\25 > * {
			padding: 12.5px 0 0 12.5px;
		}

		.row.\35 0\25 {
			margin: -12.5px 0 -1px -12.5px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 12.5px 0 0 12.5px;
		}

		.row.uniform.\35 0\25 {
			margin: -12.5px 0 -1px -12.5px;
		}

		.row.\32 5\25 > * {
			padding: 6.25px 0 0 6.25px;
		}

		.row.\32 5\25 {
			margin: -6.25px 0 -1px -6.25px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 6.25px 0 0 6.25px;
		}

		.row.uniform.\32 5\25 {
			margin: -6.25px 0 -1px -6.25px;
		}

		.\31 2u\28desktop\29, .\31 2u\24\28desktop\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28desktop\29, .\31 1u\24\28desktop\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28desktop\29, .\31 0u\24\28desktop\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28desktop\29, .\39 u\24\28desktop\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28desktop\29, .\38 u\24\28desktop\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28desktop\29, .\37 u\24\28desktop\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28desktop\29, .\36 u\24\28desktop\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28desktop\29, .\35 u\24\28desktop\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28desktop\29, .\34 u\24\28desktop\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28desktop\29, .\33 u\24\28desktop\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28desktop\29, .\32 u\24\28desktop\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28desktop\29, .\31 u\24\28desktop\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28desktop\29 + *,
		.\31 1u\24\28desktop\29 + *,
		.\31 0u\24\28desktop\29 + *,
		.\39 u\24\28desktop\29 + *,
		.\38 u\24\28desktop\29 + *,
		.\37 u\24\28desktop\29 + *,
		.\36 u\24\28desktop\29 + *,
		.\35 u\24\28desktop\29 + *,
		.\34 u\24\28desktop\29 + *,
		.\33 u\24\28desktop\29 + *,
		.\32 u\24\28desktop\29 + *,
		.\31 u\24\28desktop\29 + * {
			clear: left;
		}

		.\-11u\28desktop\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28desktop\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28desktop\29 {
			margin-left: 75%;
		}

		.\-8u\28desktop\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28desktop\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28desktop\29 {
			margin-left: 50%;
		}

		.\-5u\28desktop\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28desktop\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28desktop\29 {
			margin-left: 25%;
		}

		.\-2u\28desktop\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28desktop\29 {
			margin-left: 8.33333%;
		}

	}

	@media screen and (min-width: 737px) and (max-width: 1200px) {

		.row > * {
			padding: 25px 0 0 25px;
		}

		.row {
			margin: -25px 0 -1px -25px;
		}

		.row.uniform > * {
			padding: 25px 0 0 25px;
		}

		.row.uniform {
			margin: -25px 0 -1px -25px;
		}

		.row.\32 00\25 > * {
			padding: 50px 0 0 50px;
		}

		.row.\32 00\25 {
			margin: -50px 0 -1px -50px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 50px 0 0 50px;
		}

		.row.uniform.\32 00\25 {
			margin: -50px 0 -1px -50px;
		}

		.row.\31 50\25 > * {
			padding: 37.5px 0 0 37.5px;
		}

		.row.\31 50\25 {
			margin: -37.5px 0 -1px -37.5px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 37.5px 0 0 37.5px;
		}

		.row.uniform.\31 50\25 {
			margin: -37.5px 0 -1px -37.5px;
		}

		.row.\35 0\25 > * {
			padding: 12.5px 0 0 12.5px;
		}

		.row.\35 0\25 {
			margin: -12.5px 0 -1px -12.5px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 12.5px 0 0 12.5px;
		}

		.row.uniform.\35 0\25 {
			margin: -12.5px 0 -1px -12.5px;
		}

		.row.\32 5\25 > * {
			padding: 6.25px 0 0 6.25px;
		}

		.row.\32 5\25 {
			margin: -6.25px 0 -1px -6.25px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 6.25px 0 0 6.25px;
		}

		.row.uniform.\32 5\25 {
			margin: -6.25px 0 -1px -6.25px;
		}

		.\31 2u\28tablet\29, .\31 2u\24\28tablet\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28tablet\29, .\31 1u\24\28tablet\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28tablet\29, .\31 0u\24\28tablet\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28tablet\29, .\39 u\24\28tablet\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28tablet\29, .\38 u\24\28tablet\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28tablet\29, .\37 u\24\28tablet\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28tablet\29, .\36 u\24\28tablet\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28tablet\29, .\35 u\24\28tablet\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28tablet\29, .\34 u\24\28tablet\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28tablet\29, .\33 u\24\28tablet\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28tablet\29, .\32 u\24\28tablet\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28tablet\29, .\31 u\24\28tablet\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28tablet\29 + *,
		.\31 1u\24\28tablet\29 + *,
		.\31 0u\24\28tablet\29 + *,
		.\39 u\24\28tablet\29 + *,
		.\38 u\24\28tablet\29 + *,
		.\37 u\24\28tablet\29 + *,
		.\36 u\24\28tablet\29 + *,
		.\35 u\24\28tablet\29 + *,
		.\34 u\24\28tablet\29 + *,
		.\33 u\24\28tablet\29 + *,
		.\32 u\24\28tablet\29 + *,
		.\31 u\24\28tablet\29 + * {
			clear: left;
		}

		.\-11u\28tablet\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28tablet\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28tablet\29 {
			margin-left: 75%;
		}

		.\-8u\28tablet\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28tablet\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28tablet\29 {
			margin-left: 50%;
		}

		.\-5u\28tablet\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28tablet\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28tablet\29 {
			margin-left: 25%;
		}

		.\-2u\28tablet\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28tablet\29 {
			margin-left: 8.33333%;
		}

	}

	@media screen and (max-width: 736px) {

		.row > * {
			padding: 15px 0 0 15px;
		}

		.row {
			margin: -15px 0 -1px -15px;
		}

		.row.uniform > * {
			padding: 15px 0 0 15px;
		}

		.row.uniform {
			margin: -15px 0 -1px -15px;
		}

		.row.\32 00\25 > * {
			padding: 30px 0 0 30px;
		}

		.row.\32 00\25 {
			margin: -30px 0 -1px -30px;
		}

		.row.uniform.\32 00\25 > * {
			padding: 30px 0 0 30px;
		}

		.row.uniform.\32 00\25 {
			margin: -30px 0 -1px -30px;
		}

		.row.\31 50\25 > * {
			padding: 22.5px 0 0 22.5px;
		}

		.row.\31 50\25 {
			margin: -22.5px 0 -1px -22.5px;
		}

		.row.uniform.\31 50\25 > * {
			padding: 22.5px 0 0 22.5px;
		}

		.row.uniform.\31 50\25 {
			margin: -22.5px 0 -1px -22.5px;
		}

		.row.\35 0\25 > * {
			padding: 7.5px 0 0 7.5px;
		}

		.row.\35 0\25 {
			margin: -7.5px 0 -1px -7.5px;
		}

		.row.uniform.\35 0\25 > * {
			padding: 7.5px 0 0 7.5px;
		}

		.row.uniform.\35 0\25 {
			margin: -7.5px 0 -1px -7.5px;
		}

		.row.\32 5\25 > * {
			padding: 3.75px 0 0 3.75px;
		}

		.row.\32 5\25 {
			margin: -3.75px 0 -1px -3.75px;
		}

		.row.uniform.\32 5\25 > * {
			padding: 3.75px 0 0 3.75px;
		}

		.row.uniform.\32 5\25 {
			margin: -3.75px 0 -1px -3.75px;
		}

		.\31 2u\28mobile\29, .\31 2u\24\28mobile\29 {
			width: 100%;
			clear: none;
			margin-left: 0;
		}

		.\31 1u\28mobile\29, .\31 1u\24\28mobile\29 {
			width: 91.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 0u\28mobile\29, .\31 0u\24\28mobile\29 {
			width: 83.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\39 u\28mobile\29, .\39 u\24\28mobile\29 {
			width: 75%;
			clear: none;
			margin-left: 0;
		}

		.\38 u\28mobile\29, .\38 u\24\28mobile\29 {
			width: 66.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\37 u\28mobile\29, .\37 u\24\28mobile\29 {
			width: 58.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\36 u\28mobile\29, .\36 u\24\28mobile\29 {
			width: 50%;
			clear: none;
			margin-left: 0;
		}

		.\35 u\28mobile\29, .\35 u\24\28mobile\29 {
			width: 41.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\34 u\28mobile\29, .\34 u\24\28mobile\29 {
			width: 33.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\33 u\28mobile\29, .\33 u\24\28mobile\29 {
			width: 25%;
			clear: none;
			margin-left: 0;
		}

		.\32 u\28mobile\29, .\32 u\24\28mobile\29 {
			width: 16.6666666667%;
			clear: none;
			margin-left: 0;
		}

		.\31 u\28mobile\29, .\31 u\24\28mobile\29 {
			width: 8.3333333333%;
			clear: none;
			margin-left: 0;
		}

		.\31 2u\24\28mobile\29 + *,
		.\31 1u\24\28mobile\29 + *,
		.\31 0u\24\28mobile\29 + *,
		.\39 u\24\28mobile\29 + *,
		.\38 u\24\28mobile\29 + *,
		.\37 u\24\28mobile\29 + *,
		.\36 u\24\28mobile\29 + *,
		.\35 u\24\28mobile\29 + *,
		.\34 u\24\28mobile\29 + *,
		.\33 u\24\28mobile\29 + *,
		.\32 u\24\28mobile\29 + *,
		.\31 u\24\28mobile\29 + * {
			clear: left;
		}

		.\-11u\28mobile\29 {
			margin-left: 91.66667%;
		}

		.\-10u\28mobile\29 {
			margin-left: 83.33333%;
		}

		.\-9u\28mobile\29 {
			margin-left: 75%;
		}

		.\-8u\28mobile\29 {
			margin-left: 66.66667%;
		}

		.\-7u\28mobile\29 {
			margin-left: 58.33333%;
		}

		.\-6u\28mobile\29 {
			margin-left: 50%;
		}

		.\-5u\28mobile\29 {
			margin-left: 41.66667%;
		}

		.\-4u\28mobile\29 {
			margin-left: 33.33333%;
		}

		.\-3u\28mobile\29 {
			margin-left: 25%;
		}

		.\-2u\28mobile\29 {
			margin-left: 16.66667%;
		}

		.\-1u\28mobile\29 {
			margin-left: 8.33333%;
		}

	}

/* Basic */

	body, input, textarea, select {
		font-family: 'Source Sans Pro', sans-serif;
		font-weight: 400;
		background: #ffffff;
		color: #8f8477;
	}

	form input, form select, form textarea {
		-webkit-appearance: none;
	}

	br.clear {
		clear: both;
	}

	h2 {
		font-weight: 600;
	}

	p, ul, ol, dl, table {
		margin-bottom: 1em;
	}

	p {
		line-height: 1.75em;
	}

	a {
		color: #FF785C;
	}

		a:hover {
			text-decoration: none;
		}

	strong {
		font-weight: 700;
		color: #3d2220;
	}

	section, article {
		margin-bottom: 3em;
	}

		section > :last-child, section:last-child, article > :last-child, article:last-child {
			margin-bottom: 0;
		}

	footer {
		padding-top: 1em;
	}

	.image {
		display: inline-block;
	}

		.image img, .image.fit {
			display: block;
			width: 100%;
		}

		.image.featured {
			display: block;
			width: 100%;
			margin: 0 0 2em 0;
		}

		.image.left {
			float: left;
			margin: 0 2em 2em 0;
		}

		.image.centered {
			display: block;
			margin: 0 0 2em 0;
		}

			.image.centered img {
				margin: 0 auto;
				width: auto;
			}

	.button {
		-moz-transition: background-color 0.25s ease-in-out;
		-webkit-transition: background-color 0.25s ease-in-out;
		-ms-transition: background-color 0.25s ease-in-out;
		transition: background-color 0.25s ease-in-out;
		display: inline-block;
		padding: 0.75em 1.5em 0.75em 1.5em;
		background: #ff785c;
		text-decoration: none;
		font-size: 1.10em;
		font-weight: 700;
		color: #FFFFFF !important;
	}

		.button:hover {
			background-color: #ff8d71;
		}

		.button:active {
			background-color: #ef684c;
		}

		.button.alt {
			background: #361d1b;
			color: #FFFFFF;
		}

			.button.alt:hover {
				background-color: #492C2A;
			}

			.button.alt:active {
				background-color: #260d0b;
			}

	.bullet {
		padding: 0em 1em 0 1em;
		color: #ff785c;
	}

	ul.style1 li {
		padding: 0.80em 0em;
		border-top: 1px solid #F3F0DB;
	}

	ul.style1 a {
		color: #968d80;
	}

	ul.style1 .first {
		padding-top: 0em;
		border: none;
	}

	ul.style2 {
		overflow: hidden;
	}

		ul.style2 li {
			display: inline-block;
			margin-left: 0.5em;
		}

			ul.style2 li:first-child {
				margin-left: 0;
			}

			ul.style2 li a {
				-moz-transition: background-color 0.25s ease-in-out;
				-webkit-transition: background-color 0.25s ease-in-out;
				-ms-transition: background-color 0.25s ease-in-out;
				transition: background-color 0.25s ease-in-out;
				text-decoration: none;
				background: #F3F0DB;
				display: block;
				width: 42px;
				height: 42px;
				line-height: 42px;
				text-align: center;
			}

				ul.style2 li a .label {
					display: none;
				}

				ul.style2 li a:before {
					-moz-transition: color 0.25s ease-in-out;
					-webkit-transition: color 0.25s ease-in-out;
					-ms-transition: color 0.25s ease-in-out;
					transition: color 0.25s ease-in-out;
					display: inline-block;
					font-family: FontAwesome;
					text-decoration: none;
					font-style: normal;
					font-weight: normal;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					font-size: 24px;
					color: #FF785C;
				}

				ul.style2 li a:hover {
					background: #FF785C;
				}

					ul.style2 li a:hover:before {
						color: #F3F0DB;
					}

	ul.style3 {
		padding-top: 2em;
	}

		ul.style3 li {
			padding: 0.80em 0em;
			border-top: 1px solid #F3F0DB;
		}

		ul.style3 a {
			color: #968d80;
		}

		ul.style3 .first {
			padding-top: 0em;
			border: none;
		}

	.box {
		border-top: 10px solid #ff785c;
	}

	.byline {
		display: block;
		font-weight: 200;
		font-style: italic;
	}

	.thumbnail {
		outline: 0;
	}

		.thumbnail .image-full {
			margin-bottom: 0;
		}

		.thumbnail .titlebar {
			-moz-transition: background-color 0.25s ease-in-out;
			-webkit-transition: background-color 0.25s ease-in-out;
			-ms-transition: background-color 0.25s ease-in-out;
			transition: background-color 0.25s ease-in-out;
			display: block;
			background: #3d2220;
			border-bottom: 1px solid #0c0606;
			text-align: center;
			font-weight: 600;
			color: #FFFFFF;
			padding: 1em 0 1em 0;
		}

		.thumbnail a {
			text-decoration: none;
		}

		.thumbnail:hover .titlebar {
			background: #4d2725;
		}

/* Icons */

	.icon {
		text-decoration: none;
	}

		.icon:before {
			display: inline-block;
			font-family: FontAwesome;
			font-size: 1.25em;
			text-decoration: none;
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		.icon > .label {
			display: none;
		}

/* Poptrox */

	.poptrox-popup {
		-moz-box-sizing: content-box;
		-webkit-box-sizing: content-box;
		-ms-box-sizing: content-box;
		box-sizing: content-box;
	}

/* Wrappers */

	#header-wrapper {
		position: relative;
		overflow: hidden;
		background: url("../../assets/img/pic01.jpg");
		background-size: cover;
		background-position: top center;
		z-index: 1;
	}

		#header-wrapper:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: url("../../assets/img/bg01.png");
			z-index: -1;
			opacity: 0.5;
		}

	#banner-wrapper {
		background: #361d1b;
		background: rgba(54, 29, 27, 0.8);
	}

	#main-wrapper {
		overflow: hidden;
	}

	.wrapper.featured {
		text-align: center;
	}

	.wrapper.style1 {
		overflow: hidden;
	}

		.wrapper.style1 h2 {
			color: #3d2220;
		}

		.wrapper.style1 .byline {
			color: #ff9966;
		}

	.wrapper.style2 {
		overflow: hidden;
		background: #311917;
	}

		.wrapper.style2 header {
			background: #3d2220;
		}

		.wrapper.style2 h2 {
			color: #FFFFFF;
		}

		.wrapper.style2 .byline {
			color: #ff9966;
		}

	.wrapper.style3 {
		overflow: hidden;
		background: #ff785c;
		color: #68362b;
	}

		.wrapper.style3 h2 {
			color: #FFFFFF;
		}

		.wrapper.style3 .byline {
			color: #361d1b;
		}

		.wrapper.style3 a {
			color: inherit;
		}

/* Header */

	#header {
		margin: 0;
	}

		#header h1 {
			text-align: center;
			font-weight: 400;
		}

			#header h1 a {
				display: inline-block;
				background: #ff785c;
				text-decoration: none;
				text-transform: uppercase;
				font-weight: 900;
				color: #FFFFFF;
			}

		#header span {
			font-weight: 200;
			color: #727272;
		}

/* Banner */

	#banner {
		text-align: center;
	}

		#banner h2, #banner p {
			color: #FFFFFF;
		}

		#banner h2 {
			font-weight: 900;
		}

		#banner p {
			font-weight: 200;
			font-style: italic;
			color: #aaa;
			margin-bottom: 0;
		}

/* Main */

	#main {
		overflow: hidden;
	}

		#main h2, #main h3 {
			font-weight: 600;
			color: #3d2220;
		}

		#main .byline {
			color: #ff9966;
		}

/* Portfolio */

	#portfolio {
		overflow: hidden;
	}

		#portfolio .titles {
			text-align: center;
		}

			#portfolio .titles li {
				-moz-transition: background-color 0.25s ease-in-out;
				-webkit-transition: background-color 0.25s ease-in-out;
				-ms-transition: background-color 0.25s ease-in-out;
				transition: background-color 0.25s ease-in-out;
				cursor: pointer;
				outline: 0;
				background: #311917;
				text-decoration: none;
				color: #FFFFFF;
				font-weight: 600;
			}

				#portfolio .titles li:hover {
					background: #492C2A;
				}

				#portfolio .titles li.active {
					background: #ff785c;
				}

		#portfolio .slides li {
			display: none;
		}

/* Footer */

	#footer h2 {
		color: #3d2220;
		font-weight: 600;
	}

	#footer a {
		color: #8f8477;
	}

/* Copyright */

	#copyright {
		text-align: center;
		color: #8f8477;
	}

		#copyright span {
			display: inline-block;
			background: #f3f0db;
		}

/* Desktop */

	@media screen and (min-width: 737px) {

		/* Basic */

			body, input, textarea, select {
				font-size: 11.50pt;
			}

			header {
				margin-bottom: 1.75em;
			}

			.box {
				padding: 4em 0em 2em 0em;
			}

		/* Poptrox */

			.poptrox-popup {
				background: #fff;
				border: solid 0.35em #fff;
				box-shadow: 0 0.5em 3em 0 rgba(0, 0, 0, 0.25);
			}

				.poptrox-popup .caption {
					text-align: center;
					position: absolute;
					bottom: 0;
					left: 0;
					height: 3em;
					line-height: 3em;
					width: 100%;
					color: #fff;
					background: #361d1b;
					background: rgba(54, 29, 27, 0.8);
				}

				.poptrox-popup .closer, .poptrox-popup .nav-next, .poptrox-popup .nav-previous {
					display: inline-block;
					font-family: FontAwesome;
					font-size: 1.25em;
					text-decoration: none;
					font-style: normal;
					font-weight: normal;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					color: #fff;
				}

				.poptrox-popup .closer {
					width: 2em;
					height: 2em;
					line-height: 1.85em;
					font-size: 24px;
					text-align: center;
					position: absolute;
					top: -2em;
					right: -2em;
				}

					.poptrox-popup .closer:before {
						content: '\f00d';
					}

				.poptrox-popup .nav-next, .poptrox-popup .nav-previous {
					-moz-transition: opacity 0.25s ease-in-out;
					-webkit-transition: opacity 0.25s ease-in-out;
					-ms-transition: opacity 0.25s ease-in-out;
					transition: opacity 0.25s ease-in-out;
					position: absolute;
					top: 0;
					height: 100%;
					width: 50%;
					cursor: pointer;
					opacity: 0;
					font-size: 2em;
				}

					.poptrox-popup .nav-next:before, .poptrox-popup .nav-previous:before {
						position: absolute;
						top: 50%;
						width: 3em;
						height: 3em;
						line-height: 3em;
						margin-top: -1.5em;
						text-align: center;
					}

					.poptrox-popup .nav-next:hover, .poptrox-popup .nav-previous:hover {
						opacity: 1.0;
					}

				.poptrox-popup:hover .nav-next, .poptrox-popup:hover .nav-previous {
					opacity: 0.25;
				}

				.poptrox-popup .nav-next {
					right: 0;
				}

					.poptrox-popup .nav-next:before {
						content: '\f054';
						right: 0;
					}

				.poptrox-popup .nav-previous {
					left: 0;
				}

					.poptrox-popup .nav-previous:before {
						content: '\f053';
						left: 0;
					}

		/* Wrappers */

			#header-wrapper {
				background-position: center center !important;
			}

				#header-wrapper:after {
					background: url("../../assets/img/shadow.svg");
					background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
					background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
					background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
					background: linear-gradient(top, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 8em;
					z-index: -2;
				}

			#footer-wrapper {
				padding: 5em 0 5em 0;
			}

			.wrapper {
				padding: 5em 0 5em 0;
			}

				.wrapper.style1 h2 {
					letter-spacing: -1px;
					font-size: 2em;
				}

				.wrapper.style1 .byline {
					padding: 0.50em 0em 0em 0em;
					letter-spacing: -1px;
					font-size: 1.50em;
				}

				.wrapper.style3 h2 {
					letter-spacing: -1px;
					font-size: 2em;
				}

				.wrapper.style3 .byline {
					padding: 0.50em 0em 0em 0em;
					font-size: 1.50em;
				}

		/* Header */

			#header {
				padding: 7em 0 5em 0;
			}

				#header h1 {
					letter-spacing: -1px;
					font-size: 2.50em;
				}

					#header h1 a {
						padding: 0.35em 0.85em 0.35em 0.85em;
						font-size: 1.30em;
					}

			.homepage #header {
				padding: 12em 0 10em 0;
			}

		/* Nav */

			#nav {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				padding: 1em 0em 1em 0em;
				width: 100%;
			}

				#nav ul {
					margin: 0em 0em 0em 0em;
					padding: 0;
					text-align: center;
				}

				#nav > ul > li > ul {
					display: none;
				}

				#nav a, #nav span {
					-moz-transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
					-webkit-transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
					-ms-transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
					transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
					outline: 0;
					display: inline-block;
					padding: 0.75em 1em;
					text-decoration: none;
					text-transform: uppercase;
					font-size: 12.25pt;
					font-weight: 900;
					color: black;
					
				}

				#nav li {
					display: inline-block;
					margin: 0em 0.50em;
					color: black;
				}

					#nav li.active a, #nav li.active span {
						color: black;
						background: #ff785c;
					}

					#nav li:hover a {
						color: black;
					}

						#nav li:hover a.submenu {
							-moz-transition: none;
							-webkit-transition: none;
							-ms-transition: none;
							transition: none;
						}

				#nav .first {
					border-left: none;
				}

			.dropotron {
				background: #222;
				background: rgba(24, 24, 24, 0.9);
				padding: 1.25em 1.5em 1.25em 1.5em;
				border-bottom: solid 1px #000;
				min-width: 16em;
			}

				.dropotron a, .dropotron span {
					-moz-transition: color 0.25s ease-in-out;
					-webkit-transition: color 0.25s ease-in-out;
					-ms-transition: color 0.25s ease-in-out;
					transition: color 0.25s ease-in-out;
					line-height: 2.75em;
					text-decoration: none;
					text-transform: uppercase;
					font-weight: 900;
					color: #aaa;
					color: rgba(255, 255, 255, 0.6);
				}

				.dropotron li:hover > a, .dropotron li:hover > span {
					color: #fff;
				}

				.dropotron.level-0 {
					margin-top: 2em;
					font-size: 0.9em;
				}

					.dropotron.level-0:before {
						position: absolute;
						top: -10px;
						left: 15px;
						content: '';
						border-bottom: solid 10px #222;
						border-bottom-color: rgba(24, 24, 24, 0.9);
						border-left: solid 10px transparent;
						border-right: solid 10px transparent;
					}

		/* Banner */

			#banner {
				padding: 5em 0 5em 0;
			}

				#banner h2 {
					letter-spacing: -1px;
					font-size: 3.50em;
				}

				#banner p {
					letter-spacing: -1px;
					font-size: 2em;
					margin: 0.5em 0 0 0;
				}

		/* Main */

			#main {
				overflow: hidden;
				padding: 5em 0em 3em 0;
			}

				#main header {
					margin-bottom: 3em;
				}

				#main h2, #main h3 {
					letter-spacing: -1px;
					font-size: 2em;
				}

				#main .byline {
					padding: 0.50em 0em 0em 0em;
					letter-spacing: -1px;
					font-size: 1.50em;
				}

				#main .main-title {
					font-size: 3.25em;
					letter-spacing: -2px;
				}

		/* Footer */

			#footer {
				overflow: hidden;
			}

				#footer h2 {
					letter-spacing: -1px;
					font-size: 2em;
				}

		/* Copyright */

			#copyright {
				margin-top: 5em;
			}

				#copyright span {
					padding: 1.20em 2em;
				}

		/* Portfolio */

			#portfolio {
				overflow: hidden;
				margin-top: -5em;
			}

				#portfolio header {
					margin: 0 0 4.5em 0;
					padding: 6em 0 0 0;
				}

				#portfolio h2 {
					letter-spacing: -1px;
					font-size: 2em;
				}

				#portfolio .byline {
					margin: 1em 0 0 0;
					letter-spacing: -1px;
					font-size: 1.50em;
				}

				#portfolio .titles {
					margin-top: 2.5em;
				}

					#portfolio .titles li {
						display: inline-block;
						margin: 0em .50em;
						padding: 0.90em 1.30em;
						text-decoration: none;
						font-size: 1.10em;
					}

	}

/* Tablet */

	@media screen and (min-width: 737px) and (max-width: 1200px) {

		/* Basic */

			body {
				min-width: 1000px;
				font-size: 10pt;
			}

			input, textarea, select {
				font-size: 10pt;
			}

		/* Wrappers */

			#footer-wrapper, .wrapper {
				padding: 3.5em 0 3.5em 0;
			}

		/* Header */

			#header {
				padding: 5em 0 3em 0;
			}

			.homepage #header {
				padding: 7.5em 0 7.5em 0;
			}

		/* Nav */

			.dropotron.level-0 {
				font-size: 0.8em;
			}

		/* Banner */

			#banner {
				padding: 3em 0 3em 0;
			}

				#banner h2 {
					font-size: 3em;
				}

				#banner p {
					margin: 0.25em 0 0 0;
				}

		/* Main */

			#main {
				padding: 3em 0em 2em 0;
			}

				#main .main-title {
					font-size: 3.25em;
					letter-spacing: -1.5px;
				}

		/* Copyright */

			#copyright {
				margin-top: 3em;
			}

	}

/* Mobile */

	#navPanel, #titleBar {
		display: none;
	}

	@media screen and (max-width: 736px) {

			.logoWrapper {
				width: 100% !important;
				border-radius: 0 !important;
			}

		/* Basic */

			html, body {
				overflow-x: hidden;
			}

			body, input, textarea, select {
				line-height: 1.75em;
				font-size: 10.50pt;
				letter-spacing: 0;
			}

			h2, h3, h4, h5, h6 {
				font-size: 1.5em;
			}

			section, article {
				clear: both;
				margin: 2em 0 2em 0 !important;
			}

			header {
				margin-bottom: 1.5em;
			}

			.button {
				display: block;
				text-align: center;
			}

			.box {
				padding: 3em 0em 2em 0em;
			}

			.thumbnail {
				margin: 1em 0 1em 0;
			}

		/* Poptrox */

			.poptrox-popup {
				border: solid 0.25em #fff;
				background: #fff;
			}

		/* Off-Canvas Navigation */

			#page-wrapper {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				padding-bottom: 1px;
			}

			#titleBar {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				display: block;
				height: 44px;
				left: 0;
				position: fixed;
				top: 0;
				width: 100%;
				z-index: 10001;
			}

				#titleBar .toggle {
					text-indent: -9999px;
					width: 90px;
					height: 50px;
					opacity: 0.75;
				}

					#titleBar .toggle:before {
						content: '';
						position: absolute;
						left: 16px;
						top: 16px;
						background: #3e3e3e;
						background: rgba(80, 80, 80, 0.75);
						width: 58px;
						height: 38px;
					}

					#titleBar .toggle:after {
						content: '';
						position: absolute;
						left: 32px;
						top: 29px;
						width: 44px;
						height: 44px;
						background: url("../../assets/img/toggle.svg") 0 0 no-repeat;
						opacity: 0.75;
					}

					#titleBar .toggle:active {
						opacity: 1.0;
					}

			#navPanel {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transform: translateX(-275px);
				-webkit-transform: translateX(-275px);
				-ms-transform: translateX(-275px);
				transform: translateX(-275px);
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				display: block;
				height: 100%;
				left: 0;
				overflow-y: auto;
				position: fixed;
				top: 0;
				width: 275px;
				z-index: 10002;
				background: #171717 url("../../assets/img/bg01.jpg") repeat;
			}

				#navPanel:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: url("../../assets/img/bg01.png");
					z-index: 1;
				}

				#navPanel nav {
					position: relative;
					z-index: 2;
				}

				#navPanel .link {
					display: block;
					color: #999;
					text-decoration: none;
					height: 44px;
					line-height: 44px;
					border-top: 1px solid rgba(255, 255, 255, 0.1);
					padding: 0 1.5em 0 1.5em;
				}

					#navPanel .link:first-child {
						border-top: 0;
					}

				#navPanel .indent-1 {
					display: inline-block;
					width: 1em;
				}

				#navPanel .indent-2 {
					display: inline-block;
					width: 2em;
				}

				#navPanel .indent-3 {
					display: inline-block;
					width: 3em;
				}

				#navPanel .indent-4 {
					display: inline-block;
					width: 4em;
				}

				#navPanel .indent-5 {
					display: inline-block;
					width: 5em;
				}

				#navPanel .depth-0 {
					color: #fff;
				}

			body.navPanel-visible #page-wrapper {
				-moz-transform: translateX(275px);
				-webkit-transform: translateX(275px);
				-ms-transform: translateX(275px);
				transform: translateX(275px);
			}

			body.navPanel-visible #titleBar {
				-moz-transform: translateX(275px);
				-webkit-transform: translateX(275px);
				-ms-transform: translateX(275px);
				transform: translateX(275px);
			}

			body.navPanel-visible #navPanel {
				-moz-transform: translateX(0);
				-webkit-transform: translateX(0);
				-ms-transform: translateX(0);
				transform: translateX(0);
			}

		/* Wrappers */

			#header-wrapper {
				position: relative;

			/* Adjust the 75% below to reposition the horizontal center of your background image */

				background-position: 75% center !important;
			}

			#footer-wrapper, .wrapper {
				padding: 1em 20px 1em 20px;
			}

			#main-wrapper {
				padding: 0 20px 0 20px;
			}

		/* Nav */

			#nav {
				display: none;
			}

		/* Header */

			#header {
				padding: 6em 0 4em 0;
			}

				#header h1 {
					letter-spacing: -1px;
					font-size: 1.75em;
				}

					#header h1 a {
						padding: 0.35em 0.85em 0.35em 0.85em;
					}

		/* Banner */

			#banner {
				padding: 2em;
			}

				#banner h2 {
					letter-spacing: -1px;
					font-size: 2em;
					line-height: 1.25em;
				}

				#banner p {
					letter-spacing: -1px;
					font-size: 1.35em;
					line-height: 1.5em;
					margin-top: 0.5em;
				}

		/* Portfolio */

			#portfolio {
				overflow: visible;
				margin-top: -1em !important;
			}

				#portfolio header {
					position: relative;
					margin: 0 -20px 1.5em -20px !important;
					padding: 3em 20px 0.5em 20px;
				}

				#portfolio .titles {
					margin-top: 1.5em;
				}

					#portfolio .titles li {
						padding: 0.50em 1.50em;
						margin: 0.75em 0 0.75em 0;
					}

		/* Copyright */

			#copyright {
				margin: 4em 0 1em 0;
			}

				#copyright span {
					padding: 1.25em 1.5em 1.25em 1.5em;
				}

	}


.logoWrapper {
	width: 50%; 
	margin-left: auto; 
	margin-right: auto; 
	background: rgba(255,255,255,0.9); 
	margin-top: 55px; 
	margin-bottom: 55px; 
	padding: 15px; 
	border-radius: 3px;
}


.swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  height: 80%;
  width: 100%;
}
.gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}